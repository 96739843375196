.dato{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#profilo,#profilo > div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#profilo{
    animation: solo-appari 1s forwards;
    overflow-x: hidden;
}

#profilo .campo{
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}
#profilo .campo.psw{
    margin-top:0px!important;
    margin-bottom: 30px;
}
#profilo .campo span{
    color:var(--secondary);
}

#profilo .campo input{
    outline: none;
    border-radius: 20px;
    padding:5px 20px 5px 20px;
    border:none;
}

#no-accesso{
    font-size: 2rem;
    text-align: center;
    margin-top: 50%;
    margin-bottom: 50%;
    box-sizing: border-box;
}

#profilo input[type="button"]{
    border-radius: 50px;
    width: 300px;
    padding: 10px 25px 10px 25px;
    margin-top:20px;
    background-color: var(--secondary);
    color: var(--primary);
    font-weight: bold;
    outline:none;
    border:none;
    box-shadow: 2px 2px 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 300ms;
    cursor: pointer;
}

#profilo input[type="button"]:last-child{
    background-color: rgb(130, 0, 0);
}

#profilo input[type="button"]:last-child:hover{
    background-color: rgb(203, 1, 1);
}

#profilo input[type="button"]:hover{
    background-color: var(--detail);
}

#user-logo{
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


#user-logo i{
    background-color: var(--primary);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    font-size: 2rem;
    text-align: center;
    line-height: 80px;
    box-shadow: 4px 4px 1px 3px rgba(0, 0, 0, 0.2);
}

#user-logo h1{
    font-size: 1.7rem;
    margin-left: 30px;
}


#user_dati_cont{
    min-width: fit-content;
    background-color: var(--primary);
    padding: 40px;
    border-radius: 20px;
    margin-bottom: 50px;
    box-shadow: 4px 4px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#user_dati_cont > h1{
    margin-top:-20px;
    font-size: 1.5rem;
    border-bottom: 2px solid black;
}

#dati_input{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.dato span:first-child{
    font-size: 1em;
    font-weight: bold;
    color: var(--text);
}

.dato span:last-child{
    color: var(--text);
    font-size: 0.9em;
}

@media (max-width: 700px){
    #user_dati_cont{
        max-width: 80%;
        margin-top: 30px;
        padding: 30px;
        box-sizing: border-box;
    }
    #user-logo{
        margin-top: 10px;
        margin-bottom: -20px;
    }
    #user-logo i{
        font-size: 1.3rem;
        min-width: 50px!important;
        width: 50px!important;
        height: 50px;
        margin-right: 20px;
        line-height: 50px;
    }#user-logo h1{
        margin-left: 0px!important;
    }
    #user-logo h1,#user_dati_cont h1{
        font-size: 1.4rem;
        padding: 0px;
        text-align: center;
    }
    .dato{
        font-size: 1rem;
    }
    #user_dati_cont input{
        font-size: 1rem;
        width: 250px!important;
    }
}