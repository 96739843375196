:root{
    --primary:#f4f4f9;
    --secondary:#2f4550;
    --sfumatura:linear-gradient(45deg, rgba(47,69,80,1) 0%, rgba(0,0,0,1) 100%);
    --sfumatura-due:linear-gradient(135deg, var(--secondary) 20%, var(--detail) 100%);
    --text:#000000;
    --detail:#586f7c;
    --detail-secondary:#b8dbd9;

    --primary-back:hsla(240, 29%, 97%, 0.4);
    --secondary-back:hsl(200, 26%, 25%, 0.4);
    --detail-back:hsl(202, 17%, 42%, 0.4);
    --detail-secondary-back:hsl(177, 33%, 79%, 0.4);
}

@keyframes solo-appari {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    box-shadow: inset 2px 0px 10px rgba(0, 0, 0, 0.315);
}

  /* Handle */
::-webkit-scrollbar-thumb {
    background: var(--detail);
    box-shadow: inset 2px 0px 10px rgba(0, 0, 0, 0.315);
}

  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--secondary); 
}

*{
    font-size: 1.2rem;
}

.logo{
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    width: 90%;
}

.logo a{
    width: 100%;
    height: 100%;
}

.logo img{
    height: 100%;
}

.logo .logo-den-link{
    height: fit-content;
    width: 60px;
    height: 60px;
}

.logo .logodenina{
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    width: 50px;
    height: 50px;
    max-height: 100%;
}



a{
    text-decoration: none;
    color: inherit;
}

select{
    border:none;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
    background-color: var(--primary);
    border-radius: 10px;
    outline: none;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 1rem;
}
hr{
    margin-top: 50px;
    margin-bottom: 50px;
    height: 2px;
    background-color: rgb(0, 0, 0,0.15);
    border: none;
}

body,html{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
body.preload *{
    animation-duration: 0s !important;
}
/* #root{
    overflow-y: hidden;
} */

html{
    background-color: var(--primary-back);
    background: linear-gradient(135deg, var(--detail-back) 25%, transparent 25%) -40px 0/ 80px 80px, linear-gradient(225deg, var(--secondary-back) 25%, transparent 25%) -40px 0/ 80px 80px, linear-gradient(315deg, var(--detail-back) 25%, transparent 25%) 0px 0/ 80px 80px, linear-gradient(45deg, var(--secondary-back) 25%, var(--primary-back) 25%) 0px 0/ 80px 80px;
    background-size: 30%;
    background-repeat: repeat-x;
    background-attachment: fixed;
}

::placeholder{
    color: rgba(0, 0, 0, 0.4);
}

/* SWITCH */

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    max-width: 60px;
    min-width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--detail);
    -webkit-transition: .4s;
    transition: .4s;
}

#profilo .slider::before{
    background-color: var(--primary);
    font-size: 1rem;
}

.slider:before {
    content: "No";
    position: absolute;
    text-align: center;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: black; /*PALLA*/
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--primary);
}


#profilo input:checked + .slider {
    background-color: var(--secondary);
}

input:checked + .slider:before {
    content: "Si";
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* FINE SWITCH */

@media (max-width: 700px){
    ::-webkit-scrollbar {
        width: 5px;
    }
    .logo{
        height: 30px;    
    }
}