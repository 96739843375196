#home{
    animation: solo-appari 700ms forwards;
    overflow-x: hidden;
}

#precont{
    background-image: url("./img/copertina.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 10px var(--secondary);
}

#precont>div{
    margin-left: 70px;
}

#precont h1{
    color: var(--primary);
    font-size: 2rem;
}

#splashscreen{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    background-color: var(--primary);
    z-index: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    display: none;
    transition: all 300ms;
}

#splashscreen .logo{
    width: 70%;
    height: fit-content;
    animation: logosplash 500ms forwards;
}

@keyframes logosplash {
    from{
        transform: translateY(-500px);
    }
    to{
        transform: translateY(100px);
    }
}

#splashscreen .logo img{
    height: 45px;
    width: 100%;
}

#splashscreen::before{
    content: "";
    position: absolute;
    width: 55px;
    height: 55px;
    background-size: contain;
    background-image: url("/public/favicon.ico");
    background-repeat: no-repeat;
    margin-right: 50%;
    margin-left: 30%;
    border-radius: 50%;
    animation: pallasplash 1s ease-in forwards;
    z-index: 3;
}

@keyframes pallasplash{
    0%{
        transform: translateY(-500px);
        transform: translateX(500px);
    }
    50%{
        transform: translateY(300px);
    } 
    100%{
        transform: translateY(100px);
        transform: translateX(500px);
    }  
}

#racchetta{
    position: absolute;
    width: 70px;
    height: 220px;
    font-size: 3rem;
    margin-top: 280px;
    background-size: contain;
    background-image: url("./img/racchetta.png");
    left:110px;
    background-repeat: no-repeat;
    animation: muovirac 450ms ease-in;
}

@keyframes muovirac {
    0%{
        transform:rotate(-80deg)
    }
}

#precont::before{
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-image: url("/public/favicon.ico");
    background-repeat: no-repeat;
    margin-right: 50%;
    margin-left: 75%;
    border-radius: 50%;
    box-shadow: 20px 20px 5px rgba(0, 0, 0, 0.2);
    animation: palla 2s infinite ease alternate-reverse;
}

@keyframes palla {
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-100px);
    }
    100%{
        transform: translateX(1000px);
    }
}

.cards{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap:40px;
    justify-content: center;
    justify-items: center;
    width: 70%;
    margin: auto;
    margin-top: 50px;
}

.card{
    background-color: var(--primary);
    color: var(--primary);
    border-radius: 10px;
    padding: 20px;
    transition: all 1s;
    box-sizing: border-box;
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 400px;
    flex-direction: column;
    justify-content: center;
    height: 250px;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.card h2{
    width: 100%;
    background-color: var(--detail);
    border-radius: 20px;
    padding: 5px;
    margin-top: 50px;
}

.card:not(.sponsor):hover::before, .card:not(.sponsor):hover::after{
    width: 100%;
    border-radius: 0px;
}

.card:not(.sponsor)::before,.card:not(.sponsor)::after{
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    transition: all 1s;
    background-color:var(--detail);
    right: 0px;
    border-radius: 50% 0px 0px 0px;
    bottom: 0px;
}

.card:not(.sponsor)::after{
    left: 0px;
    top:0;
    border-radius: 0px 0px 50% 0px;
}

.card a{
    padding: 5px 20px 5px 20px;
    box-shadow: inset 0px 0px 1px 5px var(--secondary);
    transition: all 400ms;
    margin-bottom: 50px;
    color:var(--secondary);
}

.card a:hover{
    color:var(--primary);
    box-shadow: inset 0px 0px 1px 30px var(--secondary);
}

.card.sponsor{
    padding: 0;
    height: fit-content;
}

.card.sponsor img{
    max-width: 100%;
    object-fit:cover;
}

.info-cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 200px;
    position: relative;
    overflow: hidden;
}

.info-cont:last-child{
    border-top: 5px solid white;
    margin-bottom: 50px;
}

.info-cont h2{
    position: absolute;
    margin-left: 50px;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.3);
    color:var(--primary);
    padding: 15px;
    border-radius: 10px;
    width: 100%;
}

.info-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background:var(--sfumatura-due);
    width: 65%;
    height: 100%;
    margin-left:-40px;
    margin-right: -40px;
    box-sizing: border-box;
    padding: 30px;
    transform: skew(-15deg);
}

.info-text a{
    margin-top: 10px;
}

.info-text > div{
    width: 100%;
    height: fit-content;
    text-align: center;
}

.info-text > div > span{
    font-size:1rem;
    margin:auto;
    transform: skew(15deg);
}
.img-home{
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 50%;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
}

#image-contact{
    background-image: url("./img/contatti.jpeg");
}

#image-place{
    background-image: url("./img/posizione.jpeg");
}

.info-cont a{
    padding: 5px 20px 5px 20px;
    box-shadow: inset 0px 0px 1px 5px var(--primary);
    transition: all 400ms;
    text-decoration: none;
    color: var(--primary);
    transform: skew(0deg);
}

.info-cont a:hover{
    color:var(--detail);
    box-shadow: inset 0px 0px 1px 30px var(--primary);
}
.info-cont:last-of-type{
    border-top: 7px solid var(--primary);
}

@media (min-width: 700px)
{
    .cards.grid .bistrot{
        grid-column-start: 1;
        grid-column-end: 1; 
        grid-row-start: 1;   
        grid-row-end: 3;
    }
    .cards.grid .sponsor1{
        grid-column-start: 2;
        grid-column-end: 2; 
        grid-row-start: 1;   
        grid-row-end: 1;
    }
    .cards.grid .sponsor2{
        grid-column-start: 2;
        grid-column-end: 2; 
        grid-row-start: 2;   
        grid-row-end: 2;
    }
}

@media (max-width: 700px){
    #splashscreen{
        display: flex;
    }
    #precont .logo{
        height: 50px;
        margin-top: -90px;
    }
    #precont .logo img{
        width: 300px;
        height: 100%;
    }
    #precont >div {
        margin: 0px;
        padding-left: 20px;
    }
    #precont h1{
        font-size: 1.5rem;
        color: var(--text);
    }
    #precont::before{
        width: 30px;
        height: 30px;
    }
    #cards{
        width: 85%;
    }
    .info-cont{
        flex-direction: column;
        width: 85%;
        height: 270px;
        overflow: hidden;
        margin: auto;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
    }
    .info-cont h2{
        font-size: 1.5rem;
        width: 100%;
        height: fit-content;
        box-sizing: border-box;
        right: 0;
        top:0;
        bottom: 0;
        border-radius: inherit;
        text-align: center;
        padding: 5px;
    }
    .img-home{
        width: 100%;
        box-sizing: border-box;
    }
    .info-text{
        width: 100%;
        margin: 0;
        padding: 20px;
        padding-bottom: 30px;
        box-sizing: border-box;
    }
    .info-text > div{
        width: fit-content;
    }
    .info-text, .info-text > div > span{
        transform: none;
        font-size: 0.8rem;
        text-align: center;
    }
    .info-cont:last-of-type{
        border-top: none;
    }
}