#footer{
    width: 100%;
    padding:20px;
    color:var(--primary);
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
    background-color: var(--secondary);
    box-sizing: border-box;
}

#footer .sponsor img{
    max-width: 300px;
}

#footer .sponsor{
    display: flex;
    gap:10px;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center
}

#footer .logo{
    flex-direction:column ;
    justify-content: center;
    align-items: center;
    gap:20px;
    height: fit-content;
}
#footer .logo img:first-of-type{
    max-height:50px;
}

#footer #up{
    text-align: center;
}

#footer .item{
    display:flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 900px){
    #footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 90px;
    }
    #footer #logo-sporting
    {
        width: 180px;
    }
    #footer .logo{
        border-bottom: 1px solid var(--primary);
        padding-bottom: 10px;
        flex-direction: row!important;
    }
    #footer .logo img:first-of-type{
        max-height:40px;
    }
    #footer .logodenina{
        width: 40px;
        height: 40px;
    }
    #footer .sponsor{
        width: 70%;
        border-top:1px solid white;
        padding-bottom: 30px;
        border-bottom:1px solid white;
        margin-top:40px;
    }
}

@media (min-width: 700px){
    #footer{
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}