#prenota{
    position: relative;
}
#prenota #no-accesso{
    margin-top:35vh;
    margin-bottom:35vh;
}

#sceltaCampi{
    position:sticky;
    background: var(--sfumatura);
    width: 100%;
    height:75vh;
    padding: 30px;
    box-sizing: border-box;
    border-bottom: 3px solid var(--primary);
    border-top: 3px solid var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 400ms;
    z-index:300;
}
#prenota li{
    font-size: 0.9rem;
}
#sceltaCampi h1{
    margin: 0;
    text-align: center;
    color: var(--primary);
    font-size: 1.5rem;
}
#sceltaCampi input{
    color: var(--secondary);
    font-size: 1.5rem;
    background-color: var(--primary);
    border-radius: 30px;
    padding: 5px 15px 5px 15px;
    outline: none;
    border: none;
    box-shadow: 3px 3px 5px 1px black;
    transition: all 400ms;
}
#sceltaCampi input:hover{
    background-color: var(--detail-secondary);
    cursor: pointer;
}
#campi-img{
    width: 85%;
    overflow-x: hidden;
    display: grid;
    gap:30px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    overflow-y: scroll;
    height:fit-content;
    padding:20px 50px 50px 50px;
    user-select: none;
    justify-content: center;
    align-items: center;
    justify-items: center;
    box-sizing: border-box;
    margin:30px auto 30px auto;
}
#campi-img::-webkit-scrollbar{
    width: 0px;
}
#campi-img span{
    width: 100%;
    position: relative;
}
#campi-img span::before{
    content: attr(data-nome);
    position: absolute;
    width: 60%;
    font-size: 0.8rem;
    bottom:20%;
    background-color: var(--secondary);
    z-index: 1;
    border-radius: 0px 5px 5px 0px;
    left:-15px;
    text-align: center;
}
#campi-img span{
    margin: auto;
    width: fit-content;
}
#campi-img img{
    width: 250px;
    margin: auto;
    height: 140px;
    transform: scale(1.15);
    border-radius: 5px;
    box-shadow: 3px 3px 10px 2px black;
    cursor: pointer;
    transition:all 300ms;
    /* filter: blur(2px); */
    animation: solo-appari 300ms forwards;
}

/* #campi-img img.selected{
    transform: translateY(-5px);
} */

#tabella{
    border-top: 3px solid var(--primary);
    display: flex;
    border-radius: 10px;
    margin: 10px;
    animation: solo-appari 600ms forwards;
    box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.245);
}

#orari{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    left: 0;
    background-color: var(--primary);
    width: fit-content;
    border-right: 4px solid var(--secondary);
    border-radius:7px 0px 0px 10px; 
}

#orari div:last-child{
    border-radius: 0px 0px 0px 10px;
}
.titolo{
    background-color: var(--secondary);
    color: var(--primary);
    padding: 5px 30px 5px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

#orari div{
    border-bottom: 1px solid rgba(0, 0, 0, 0.36);
    width: 120px;
    height: 40px;
    text-align: center;
    padding: 5px 0px 5px 0px;
    box-sizing: border-box;
    font-size: 0.8rem;
}

.grigia{
    background-color: rgb(202, 202, 202);
}

#orari div:last-child{
    border: none;
}

#tabella-cont::-webkit-scrollbar {
    height: 10px;
}

#campoScelto{
    display: none;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 0px;
}
#nome{
    color: rgba(239, 21, 21, 0.906);
}

#campoScelto input{
    border-radius: 30px;
    outline: none;
    font-size: 1rem;
    width: 220px;
    text-align: center;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    background:var(--sfumatura);
    color: var(--primary);
    transition: all 400ms;
    background-size: 100% 100%;
    box-shadow: 3px 2px 0px #000000, 0 -2px 5px var(--secondary), 3px 4px 1px var(--detail);
}
#campoScelto input:hover{
    cursor: pointer;
    background-size: 500% 500%;
}
#giorni{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7,90px);
    /* grid-template-rows: repeat(14,40px); */
    gap:0px;
    border-bottom: 1px solid var(--primary);
    overflow-x: auto;
    box-sizing: border-box;
    /* padding-bottom: 10px; */
}
#popup-vedi-pren,#popup-prenota{
    display: none;
}
#giorni .titolo{
    border-left: 1px solid var(--primary);
    width: 90px;
}

#giorni .titolo:first-child{
    border:none;
}

#giorni input{
    font-size: 0.7rem;
    width:90px;
    height: 40px;
    padding: 0px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    /* margin-top: -40px; */
    box-shadow:inset 0px 0px 2px 0px rgba(0, 0, 0, 0.7);
    transition: all 400ms;
    overflow-x: auto;
}
#giorni input::-webkit-scrollbar{
    height: 10px;
    background-color: var(--primary);
}
#giorni input:not(:disabled):hover{
    box-shadow:inset 0px 0px 1px 20px var(--secondary);
    color: var(--primary);
    cursor: pointer;
}
#giorni input:disabled{
    color: var(--detail-secondary);
    background-color: var(--detail);
    cursor:not-allowed;
}
#popup-vedi-pren,#popup-prenota,#informazioni{
    display: flex;
    position: absolute;
    background-color: var(--primary);
    width: 45%;
    min-width: 500px;
    height: 85%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    z-index: 10;
    top:40px;
    border-radius:10px;
    flex-direction: column;
    align-items: center;
    transition: all 400ms ease-in-out;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.221);
    z-index: 2;
}

#popup-vedi-pren::before,#popup-prenota::before,#informazioni::before{
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}


#informazioni{
    padding: 20px;
    box-sizing: border-box;
}
#informazioni > div{
    overflow-y: auto;
    padding: 20px;
}
#informazioni h1{
    font-size: 1.2rem;
    text-align: center;
}
#informazioni h3{
    font-size: 1.1rem;
}
#popup-prenota input{
    border-radius: 30px;
    border: none;
    box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.405);
    padding: 5px 20px 5px 20px;
    transition: all 400ms;
    background-color: var(--secondary);
    color: var(--primary);
}
#popup-prenota input:hover{
    background-color: var(--detail);
    cursor: pointer;
}
#popup-vedi-pren > i,#popup-prenota>i,#informazioni >i{
    cursor: pointer;
    background-color: var(--primary);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.3);
    position: absolute;
    right: -10px;
    top:-10px;
    transition: all 300ms;
}
#popup-vedi-pren h2{
    border-bottom: 1px solid black;
    width: 80%;
}
#popup-vedi-pren h1,#popup-prenota h1{
    width: 100%;
    text-align: center;
}
#popup-prenota h2{
    width: 80%;
    text-align: center;
}
#pren-cont{
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;
}

#cont-check{
    width: 70%;
    margin-top: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.check-doppio{
    /* width: 120px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    accent-color: var(--secondary);
}
.check-doppio span{
    border-radius: 100%;
    padding: 0px;
    min-width: 20px;
    height: 20px;
    overflow: hidden;
    border: 3px solid var(--secondary);
    background-color: var(--secondary);
}
.check-doppio label{
    margin-left: 10px;
    font-size: 0.8rem;
}
.check-doppio span input{
    height: 20px;
    min-width: 20px;
    margin: auto;
    background-color: var(--secondary);
}
#popup-prenota h1{
    font-size: 1.3rem;
    text-align: left;
    border-bottom: 1px solid black;
    width: 80%;
}
#popup-prenota h2{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0px;
}
#info-pren{
    display: flex;
    width: 80%;
    justify-content: space-between;
}
#giocatori{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    justify-items: center;
    height: 250px;
    margin-bottom: 20px;
}
.gioc{
    background-color: var(--primary)!important;
    outline:none;
    width: 300px;
    height: 50px!important;
    border-radius: 10px!important;
    padding: 10px!important;
    box-sizing: border-box!important;
    margin: 20px;
    color: var(--text)!important;
    animation: appari 400ms forwards;
}
.gioc:hover{
    cursor: initial!important;
}
#pren-cont h3{
    width: 90%;
    padding-left: 10px;
    padding-bottom: 5px;
    margin: auto;
    margin-bottom: 10px;
    position: sticky;
    top:0;
    background-color: var(--primary);
    z-index: 10;
    border-left: 3px solid var(--secondary);
    box-sizing: border-box;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.326);
}
.prenotazione{
    background:var(--sfumatura);
    width: 90%;
    border-radius: 10px;
    margin: auto;
    box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.192);
    animation: appari 500ms forwards;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.prenotazione i{
    background-color: var(--primary);
    padding: 15px;
    border-radius: 5px;
    transition: all 300ms;
    cursor: pointer;
}
.prenotazione i:hover{
    background-color: rgb(153, 0, 0);
    color:var(--primary);
}
.campo-pren{
    display: flex;
    align-items: center;
    width: 100%;
    height: 22px;
}
.campi-pren{
    padding-right: 10px;
}
.campo-pren:first-child{
    padding-top:10px;
}
.campo-pren:last-child{
    padding-bottom:10px;
}
#tabella-cont{
    max-width: fit-content;
    margin: auto;
    overflow-x: auto;
    margin-bottom: 30px;
    display: none;
}


#orari .titolo:first-child{
    border-radius: 10px 0px 0px 0px;
}

.giorno.titolo:last-of-type{
    border-radius: 0px 10px 0px 0px;
}

#giorni{
    border-bottom: none;
}

#giorni input:last-of-type{
    border-radius: 0px 0px 10px 0px;
}
#giorni{
    overflow-x: auto;
}
.campo-pren span{
    color:var(--primary);
    word-wrap: break-word;
}

#campoNomeScelto{
    background-color: var(--primary);
    border-radius: 10px;
    padding: 0px 15px 0px 15px;
    text-align: center;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
    
}


.chiuso{
    transform: translateY(300%);
}

@media(min-width: 700px){
    .chiuso{
        opacity:0;
        visibility:hidden;
        height: 0!important;
    }
}

@media (max-width: 700px){
    .prenotazione{
        width: 92%!important;
    }
    #popup-prenota{
        overflow-y: scroll;
    }
    #popup-prenota input:last-child{
        margin-bottom: 50px;
    }
    #tabella-cont{
        margin-bottom: 0px;
    }
    #campoScelto{
        flex-direction: column;
    }
    #campoScelto input{
        margin-top: 10px;
    }
    #campi-img{
        padding: 0;
        padding-top:15px;
        width: 90%;
        box-sizing: border-box;
    }
    #campi-img img{
        width: 200px;
        box-sizing: border-box;
    }
    .campi-pren{
        max-width:60%;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .campi-pren::-webkit-scrollbar{
        height: 5px;
    }
    .campo-pren{
        height: fit-content;
        max-height: 25px;
    }
    #prenota #no-accesso{
        font-size: 1rem;
    }
    #popup-vedi-pren{
        height: 100%;
        top:0;
    }
    #popup-prenota,#popup-vedi-pren,#informazioni{
        position: fixed;
        transition: all 500ms;
        width:100%;
        min-width: 100px;
        height: 100%;
        max-height: 100%;
        top:5%;
        bottom:0;
        border-radius: 20px 20px 0px 0px;
        z-index: 1;
        box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.63);
    }
    #popup-prenota i,#popup-vedi-pren i,#informazioni i{
        top:10px;
        right: 10px;
    }
    #pren-cont{
        padding-bottom: 100px!important;
    }
    #info_btn span{
        display: none;
    }
    #info_btn{
        width: 30px!important;
    }
    #info_btn{
        position: absolute;
        right: 10px;
    }
    #informazioni{
        padding-bottom: 140px;
    }
    #informazioni p{
        font-size: 1rem;
    }
    #tutte_pren{
        margin-top: 20px!important;
    }
    #tutte_pren,#mie_pren{
        width: 70vw!important;
    }
    #popup-prenota h2{
        font-size: 1rem;
        text-align: center;
    }
}