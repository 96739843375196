#login{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    animation: log-appari 400ms forwards;
    min-height:80vh;
    overflow-x: hidden;
}

@keyframes log-appari {
    0%{
        opacity: 0;
        width: 0px;
    }
    100%{
        opacity: 1;
        width: 100%;
    }
}

#login input{
    border:none;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.315);
}

#login-cont{
    width: 450px;
    padding-top:30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:var(--sfumatura);
    border-radius: 10px;
    transition: all 1s;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.315);
}

#login-cont h1{
    color: var(--primary);
    margin-top: 30px;
}

.campo{
    display: flex;
    flex-direction: column;
    transition: all 1s;
    /* width: 80%!important; */
    animation: appari 1s forwards;
    position: relative;
}

@keyframes appari {
    0%{
        opacity: 0;
        width: 0px;
    }
    100%{
        opacity: 1;
        width: 85%;
    }
}

span{
    font-size: 0.8rem;
    padding: 5px;
    color:var(--detail-secondary);
}

.campo canvas{
    border-radius: 10px;
}

.campo canvas::before{
    content:"Codice CAPTCHA:";
    width: 100%;
    background-color: red;
    font-size: 0.8rem;
    color:var(--detail)
}

.campo input{
    width:100%;
    box-sizing: border-box;
    padding:5px;
    font-size: 1rem;
    outline:none;
    border-radius: 5px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.315);
}

.campo i{
    position: absolute;
    right: 10px;
    bottom: 7px;
}

#btn-login{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    justify-items: center;
    align-items:center;
    box-sizing: border-box;
    margin-bottom: 20px;
}

#btn-login input{
    min-width:150px;
    font-weight: bold;
    margin:20px;
    padding: 10px 20px 10px 20px;
    border-radius: 50px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.315);
    cursor: pointer;
    transition: all 200ms;
}
#btn-login input:last-child{
    grid-column: 1 / 3;
    width: 70%;
}

#btn-login input:hover{
    background-color: var(--detail);
    color: var(--primary);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}

#socio-info, #ricordami-info{
    text-align: center;
    transition: all 1s;
    width: 420px;
    opacity:0;
    font-size: 0.7rem;
}

#user:read-only{
    background-color: var(--detail-secondary);
}

.socio-cont,.ricordami-cont{
    width: 85%;
    display: flex;
    align-items:center;
    margin-top:15px;
    margin-bottom:15px;
}

.ricordami-cont{
    margin-bottom:0;
}

.socio-cont label,.ricordami-cont label{
    margin-left: 3%;
    margin-right: 1%;
}
#info{
    padding: 20px 30px 0px 20px;
    box-sizing: border-box;
    text-align: center;
    color: var(--primary);
    font-size: 1rem;
    transition: all 1s;
    opacity: 0;
    height: 0;
    width: 100%;
    overflow: hidden;
}

@media (max-width: 700px){
    #login{
        min-height: inherit;
    }
    #login-cont{
        min-height: 83vh;
    }
    #login-cont,#login{
        width: 100%;
        padding: 0;
        border-radius: 0;
        box-sizing: border-box;
    }
    #btn-login{
        flex-direction: column;
    }
    #btn-login input{
        width:60%;
    }
}