#alert-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    box-sizing: border-box;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    animation: sfoca 300ms forwards;
}

@keyframes sfoca {
    0%{backdrop-filter: blur(0px);    background-color: rgba(0, 0, 0, 0);}
    100%{backdrop-filter: blur(3px);    background-color: rgba(0, 0, 0, 0.2);}
}

#alert{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:30px;
    border-radius: 10px;
    width:350px;
    max-width:350px;
    background-color: var(--primary);
    border-top: 7px solid var(--detail);
    border-bottom: 7px solid var(--detail);
    position: absolute;
    box-sizing: border-box;
    z-index: 100;
    box-shadow: 5px 5px 1px 2px rgba(0, 0, 0, 0.244);
    animation: solo-appari 400ms forwards;
}

#alert div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}

#alert div h1{
    color: var(--text);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}
#alert i{
    margin-right: 5px;
    color: rgb(140, 0, 0);
}

#alert > i{
    position: absolute;
    top:10px;
    right:10px;
    cursor:pointer;
}