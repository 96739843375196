#info-legal{
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap:7px;
    grid-column-start: 1;
    grid-column-end: 3;
}

#info-legal div{
    font-size: 14px!important;
}