#su-di-noi{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    animation: solo-appari 1s forwards;
}

#su-di-noi .logo{
    justify-content: center;
    width: 40%;
    height: 40%;
    max-width: 200px;
}
#su-di-noi .logo img{
    height: 100%;
    width: 100%;
}

#su-di-noi a{
    background-color: var(--secondary);
    color: var(--detail-secondary);
    padding:5px 10px 5px 10px;
    border-radius: 10px;
    transition: all 300ms;
}

#su-di-noi a:hover{
    background-color: var(--detail);
    color: var(--detail-secondary);
    padding:5px 10px 5px 10px;
    border-radius: 10px;
    transform: translateY(-5px);
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.6);
}
#su-di-noi p{
    text-align: center;
    max-width: 700px;
}

#by{
    margin-top: 25px;
    font-size: 0.6rem;
}

#warning{
    font-size: 0.7rem;
    margin-top: 25px;
    margin-bottom: 0;
    font-weight: bold;
}