#navbar{
    width: 100%;
    padding:15px;
    color:var(--primary);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    background-color: var(--secondary);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#links{
    display: flex;
    justify-content: space-between;
}

#links a{
    margin-left: 10px;
}

#links a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--detail);
    padding: 5px 15px 5px 15px;
    border-radius: 30px;
    transition: all 200ms;
}

#links a i{
    margin-right: 5px;
}

#links a:hover, #links a.active{
    background-color: var(--primary);
    color: var(--text);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.344);
}
#links a:hover span, #links a.active span{
    color: var(--text); 
}

#links a.active{
    background-color: var(--detail-secondary);
}

#navbar .logo{
    justify-content: left;
    width: 100%;
}
#navbar .logo a{
    width: fit-content;
}
#navbar .logo-den-link{
    margin-left: 20px;
}
@media (max-width: 820px){
    #navbar .logo{
        justify-content: center;
    }
    #navbar{
        flex-direction: column;
    }
    #links{
        display: flex;
        margin-top: 15px;
        position: fixed;
        padding: 10px;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
        bottom: 0;
        z-index: 2;
        width: 101%;
        background-color: var(--secondary);
        border-radius: 20px 20px 0px 0px!important;
        box-sizing: border-box;
    }
    #links a{
        padding: 10px;
    }
    #links i{
        margin: 0!important;
        width: 30px;
        text-align: center;
        transition: width 300ms;
    }
    #links span{
        width: 0px;
        padding: 0px;
        text-align: center;
        overflow: hidden;
        transition: all 500ms;
    }
    #links a.active i{
        margin-right: 5px!important;
        width: inherit;
    }
    #links a.active span{
        width: 60px;
    }
}